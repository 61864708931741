import { useState } from 'react';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { Switch, Box, CircularProgress } from '@material-ui/core';
import { theme } from '@evgo/react-material-components';
import {
  GetChargerExtendQuery,
  Mutation,
  ListChargersConsumptionForExtendInput,
  useListChargersConsumptionForExtendQuery,
} from 'src/@types';
import { MutationOp } from 'src/@types/shared';
import { Tooltip } from 'src/components/shared/Tooltip';
import { Card, CardHeader } from 'src/components/shared/Card';
import { Connector, Status } from 'src/components/shared/Connector';
import { KebabMenu, MenuItem } from 'src/components/shared/KebabMenu';
import { stopCharge } from 'src/apollo/queries/chargers/stopCharge';
import { reservableConnector } from 'src/apollo/queries/chargers/reservableConnector';
import { useSnackbar, useConfirmationDialog } from 'src/lib/hooks';
import useAnalytics from 'src/lib/hooks/useAnalytics';
import { events } from 'src/lib/utils/analytics-events';

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing(5)}px;
  .title-wrapper {
    display: grid;
    grid-template-columns: 5fr 1fr;
    button {
      justify-self: end;
    }
  }
`;

export type ConnectorListProps = ListChargersConsumptionForExtendInput & {
  refetch: () => void;
  chargerName: string;
  evses: NonNullable<NonNullable<GetChargerExtendQuery['getCharger']>['evses']>;
};

type OnStopChargeProps = {
  confirmationDialog: ReturnType<typeof useConfirmationDialog>;
  stopCharger: MutationOp;
  setConnectorLoading: (loading: boolean) => void;
  connectorId?: string;
};

export const onStopCharge = ({
  confirmationDialog,
  stopCharger,
  setConnectorLoading,
  connectorId,
}: OnStopChargeProps) => {
  confirmationDialog.open({
    title: 'Stop Charge?',
    body: 'Are you sure you want to stop this charger connector? The on-going charging session will be terminated.',
    buttonConfirmText: 'Confirm',
    callback: () => {
      setConnectorLoading(false);
      stopCharger({ variables: { input: { connectorId: connectorId } } });
    },
  });
};

export default function ConnectorsList(props: ConnectorListProps) {
  const { chargerName, evses, ...input } = props;
  const snackBar = useSnackbar();
  const confirmationDialog = useConfirmationDialog();
  const [connectorName, setConnectorName] = useState('');
  const [connectorLoading, setConnectorLoading] = useState(false);
  const { track } = useAnalytics();

  const [stopCharger] = useMutation<Mutation>(stopCharge, {
    onCompleted() {
      props.refetch();
      track(events.chargerView.START_CONNECTOR_STOP_CHARGE);
      snackBar.success(
        `${connectorName} of ${chargerName} charger has stopped. It may take a few seconds for the connector status to update accordingly.`,
      );
      setConnectorLoading(false);
    },
    onError: () => {
      props.refetch();
      track(events.chargerView.START_CONNECTOR_STOP_CHARGE);
      snackBar.error('Something went wrong, please try again.');
      setConnectorLoading(false);
    },
  });

  const [toggleReservableConnector] = useMutation<Mutation>(reservableConnector, {
    onCompleted() {
      props.refetch();
      snackBar.success(`Connector reservable status changed successfully.`);
      setConnectorLoading(false);
    },
    onError: () => {
      props.refetch();
      snackBar.error('Something went wrong with the Connector Reservable flag. Please try again.');
      setConnectorLoading(false);
    },
  });

  input.chargerIds = input.chargerIds?.length ? input.chargerIds : undefined;

  const skip = !input.chargerIds?.length;

  const { data: sessionsData, loading: sessionsDataLoading } = useListChargersConsumptionForExtendQuery({
    variables: { input },
    fetchPolicy: 'cache-and-network',
    skip,
  });

  const connectors: JSX.Element[] = [];

  // Get connectors and chargers from evses and create list of components
  evses?.edges?.forEach((evse) => {
    evse?.connectors?.edges?.forEach((connector, index) => {
      const name = `Connector ${connectors.length + 1}`;
      let totalSessions = 0;
      let connectorUtilization = 0;
      if (!sessionsDataLoading) {
        sessionsData?.listChargersConsumptionForExtend?.forEach((charger) => {
          totalSessions += charger?.totalSessions;
          if (charger?.connectorId === connector?.altId) {
            connectorUtilization += charger?.totalSessions;
          }
        });
        connectorUtilization = Math.round((connectorUtilization / totalSessions) * 100);
      }
      connectors.push(
        <StyledCard key={connectors.length + 1}>
          {connectorLoading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress />
            </Box>
          ) : (
            <div className="title-wrapper">
              <Box display="flex">
                <CardHeader title={name} />
                <Box display="flex" paddingLeft={2.5}>
                  <Switch
                    key={`connector-reservable-${connector?.altId}`}
                    data-testid={`connector-reservable`}
                    checked={Boolean(connector?.reservable)}
                    onChange={() => {
                      setConnectorLoading(true);
                      track(events.chargerView.CLICKED_RESERVABLE_CONNECTOR);
                      toggleReservableConnector({
                        variables: {
                          input: { altId: connector?.altId, reservable: !Boolean(connector?.reservable) },
                        },
                      });
                    }}
                  />
                  <Box display="flex" pt={1.2}>
                    Reservable
                    <Tooltip
                      key={`connector-reservable-tooltip-${connector?.altId}`}
                      data-testid={`connector-reservable-tooltip-${connector?.altId}`}
                      content={
                        <div>
                          Reservable connectors allow your users to reserve this connector in advance (20 mins max) by
                          paying a specific fee.
                          <br />
                          Set reservation fees on the pricing tab.
                        </div>
                      }
                    />
                  </Box>
                </Box>
              </Box>
              <KebabMenu>
                {({ close }) => (
                  <>
                    <MenuItem
                      data-testid={`stop-charge-for-connector-${index}`}
                      onClick={() => {
                        setConnectorName(name);
                        onStopCharge({
                          confirmationDialog,
                          stopCharger,
                          setConnectorLoading,
                          connectorId: connector?.altId,
                        });
                        track(events.chargerView.CLICKED_CONNECTOR_STOP_CHARGE);
                        close();
                      }}
                      disabled={connector?.connectorStatusConstraint?.columnValue !== 'CHARGING'}
                    >
                      Stop Charge
                    </MenuItem>
                  </>
                )}
              </KebabMenu>
            </div>
          )}
          <Connector
            id={`connector-${connectors.length + 1}`}
            data-testid={`connector-${connectors.length + 1}`}
            status={connector?.connectorStatusConstraint?.columnValue as Status}
            utilizationMeasure={`${connectorUtilization || 0}%`}
            connectorType={connector?.connectorType?.columnText || ''}
            evseId={evse?.id || ''}
            cableLength={connector?.cableLength || '20 ft'}
            connectorMaxCurrent={connector?.connectorMaxCurrent ? `${connector?.connectorMaxCurrent} A` : '120 A'}
            connectorPlacement={
              connector?.connectorPlacementConstraint?.columnText
                ? `${connector?.connectorPlacementConstraint?.columnText}`
                : 'N/A'
            }
            connectorVoltageRange={connector?.outputVoltageRange ? `${connector?.outputVoltageRange} V` : '200 - 500 V'}
            maxKWh={String(connector?.maxConnectorPower || '-')}
          />
        </StyledCard>,
      );
    });
  });

  return <>{connectors}</>;
}
