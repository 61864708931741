import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { theme } from '@evgo/react-material-components';
import { Box, Divider } from '@material-ui/core';
import {
  LensOutlined as CircleOutlinedIcon,
  Lens as CircleIcon,
  CheckCircle as CheckCircleIcon,
} from '@material-ui/icons';
import { ChargingIcon } from '../../svgs';
import { ConnectorFeaturedItem } from './ConnectorFeaturedItem';
import { BoxItem } from '../BoxItem';

export enum Status {
  Charging = 'CHARGING',
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
  Occupied = 'OCCUPIED',
}

type Props = {
  id?: string;
  status?: Status;
  maxKWh?: string;
  utilizationMeasure?: string;
  connectorType?: string;
  cableLength?: string;
  connectorMaxCurrent?: string;
  connectorPlacement?: string;
  connectorVoltageRange?: string;
  evseId?: string;
  'data-testid'?: string;
};

const StyledBox = styled(Box)`
  width: 100%;

  .featured {
    gap: ${theme.spacing(4)}px;
  }

  div {
    hr {
      height: auto;
    }
  }
`;

const StatusIcon = {
  [Status.Charging]: <ChargingIcon />,
  [Status.Available]: (
    <Box color="#3C897C">
      <CheckCircleIcon />
    </Box>
  ),
  [Status.Unavailable]: (
    <Box color="#e0e0e0">
      <CircleOutlinedIcon />
    </Box>
  ),
  [Status.Occupied]: (
    <Box color="#3C897C">
      <CircleIcon />
    </Box>
  ),
};

export const Connector: React.FC<Props> = ({
  id,
  status,
  maxKWh,
  utilizationMeasure,
  connectorType,
  evseId,
  cableLength,
  connectorMaxCurrent,
  connectorPlacement,
  connectorVoltageRange,
  ...props
}) => {
  return (
    <StyledBox id={id} data-testid={props['data-testid']}>
      <Box display="flex" className="featured">
        <ConnectorFeaturedItem icon={status && StatusIcon[status]} title="STATUS" value={_.capitalize(status)} />
        <ConnectorFeaturedItem title="MAX kW" value={maxKWh} />
        <ConnectorFeaturedItem
          title="UTILIZATION MEASURE"
          value={utilizationMeasure}
          tip={'How often this connector is used against all the connectors on this charger for the past 30 days'}
        />
      </Box>

      <Divider />

      <Box display="flex" justifyContent="space-around">
        <BoxItem title="Connector Type" value={connectorType} />
        <Divider orientation="vertical" />
        <BoxItem title="Stall ID" value={evseId} />
        <Divider orientation="vertical" />
        <BoxItem title="Placement" value={connectorPlacement} />
        <Divider orientation="vertical" />
        <BoxItem title="Cable Length" value={cableLength} />
        <Divider orientation="vertical" />
        <BoxItem title="Connector Max Current" value={connectorMaxCurrent} />
        <Divider orientation="vertical" />
        <BoxItem title="Connector Voltage Range" value={connectorVoltageRange} />
      </Box>
    </StyledBox>
  );
};
